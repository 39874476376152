import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue')
}, {
    path: '/',
    component: () => import('../views/Home.vue'),
    children: [{
        path: '/',
        name: 'Home',
        component: () => import('../views/Index.vue')
    }, {
        path: '/index',
        name: 'index',
        component: () => import('../views/Index.vue')
    }, {
        path: '/buding/index',
        component: () => import('../views/Index.vue')
    }, {
        path: '/buding/login',
        component: () => import('../views/login/Login.vue')
    }, {
        path: 'buding/sys/user',
        name: 'user',
        component: () => import('../views/sys/SysUser.vue')
    }, {
        path: 'buding/sys/param',
        name: 'param',
        component: () => import('../views/sys/SysParam.vue')
    }, {
        path: 'buding/account/outflowData',
        name: 'outflowData',
        component: () => import('../views/account/OutflowData.vue')
    }, {
        path: 'buding/account/inflowData',
        name: 'inflowData',
        component: () => import('../views/account/InflowData.vue')
    }, {
        path: 'buding/account/flowStatistics',
        name: 'flowStatistics',
        component: () => import('../views/account/FlowStatistics.vue')
    }, {
        path: 'buding/account/flowType',
        name: 'flowType',
        component: () => import('../views/account/FlowType.vue')
    }, {
        path: 'buding/account/circulate',
        name: 'circulate',
        component: () => import('../views/account/Circulate.vue')
    }, {
        path: 'buding/account/balance',
        name: 'balance',
        component: () => import('../views/account/Balance.vue')
    }, {
        path: 'buding/account/balanceCheck',
        name: 'balanceCheck',
        component: () => import('../views/account/BalanceCheck.vue')
    }, {
        path: 'buding/doc/docCouplet',
        name: 'docCouplet',
        component: () => import('../views/doc/DocCouplet.vue')
    }, {
        path: 'buding/doc/docImage',
        name: 'docImage',
        component: () => import('../views/doc/DocImage.vue')
    }, {
        path: 'buding/doc/docPlan',
        name: 'docPlan',
        component: () => import('../views/doc/DocPlan.vue')
    }, {
        path: 'buding/doc/docSportsRecord',
        name: 'docSportsRecord',
        component: () => import('../views/doc/DocSportsRecord.vue')
    }, {
        path: 'buding/game/dnfRoleDevelop',
        name: 'dnfRoleDevelop',
        component: () => import('../views/game/DnfRoleDevelop.vue')
    }, {
        path: 'buding/game/dnfCustomEquip',
        name: 'dnfCustomEquip',
        component: () => import('../views/game/DnfCustomEquip.vue')
    }, {
        path: 'buding/game/dnfNormalEquip',
        name: 'dnfNormalEquip',
        component: () => import('../views/game/DnfNormalEquip.vue')
    }, {
        path: 'buding/game/dnfNotebook',
        name: 'dnfNotebook',
        component: () => import('../views/game/DnfNotebook.vue')
    }, {
        path: 'buding/game/genshinRoleDevelop',
        name: 'genshinRoleDevelop',
        component: () => import('../views/game/GenshinRoleDevelop.vue')
    }, {
        path: 'buding/game/genshinEquip',
        name: 'genshinEquip',
        component: () => import('../views/game/GenshinEquip.vue')
    }, {
        path: 'buding/game/genshinRolePreference',
        name: 'genshinRolePreference',
        component: () => import('../views/game/GenshinRolePreference.vue')
    }, {
        path: 'buding/game/genshinHelper',
        name: 'genshinHelper',
        component: () => import('../views/game/GenshinHelper.vue')
    }, {
        path: 'buding/game/srRoleDevelop',
        name: 'srRoleDevelop',
        component: () => import('../views/game/SrRoleDevelop.vue')
    }, {
        path: 'buding/game/srEquip',
        name: 'srEquip',
        component: () => import('../views/game/SrEquip.vue')
    }, {
        path: 'buding/game/srRolePreference',
        name: 'srRolePreference',
        component: () => import('../views/game/SrRolePreference.vue')
    }, {
        path: 'buding/util/sqlQuery',
        name: 'sqlQuery',
        component: () => import('../views/util/SqlQuery.vue')
    }, {
        path: 'buding/util/test',
        name: 'test',
        component: () => import('../views/util/Test.vue')
    }, {
        path: 'buding/util/test2',
        name: 'test2',
        component: () => import('../views/util/Test2.vue')
    }, {
        path: 'buding/util/template',
        name: 'template',
        component: () => import('../views/util/Template.vue')
    }, {
        path: 'system/projectList',
        name: 'projectList',
        component: () => import('../views/project/ProjectList.vue')
    }]
}, {
    path: '/public',
    component: () => import('../views/public/Home.vue'),
    children: [{
        path: '/',
        name: 'public',
        component: () => import('../views/public/Index.vue')
    }, {
        path: '/public/index',
        component: () => import('../views/public/Index.vue')
    }, {
        path: '/public/dnfRoleDevelop',
        name: 'dnfRoleDevelop2',
        component: () => import('../views/public/DnfRoleDevelop.vue')
    }, {
        path: '/public/dnfCustomEquip',
        name: 'dnfCustomEquip2',
        component: () => import('../views/public/DnfCustomEquip.vue')
    }]
}, {
    path: '/mobile',
    component: () => import('../views/game-mobile/Home.vue'),
    children: [{
        path: '/',
        name: 'mobile',
        component: () => import('../views/game-mobile/Index.vue')
    }, {
        path: '/mobile/index',
        component: () => import('../views/game-mobile/Index.vue')
    }, {
        path: '/mobile/game/dnfRoleDevelop',
        name: 'dnfRoleDevelop3',
        component: () => import('../views/game-mobile/DnfRoleDevelop.vue')
    }, {
        path: '/mobile/game/dnfCustomEquip',
        name: 'dnfCustomEquip3',
        component: () => import('../views/game-mobile/DnfCustomEquip.vue')
    }]
}, {
    path: '/mobile/flowDataRecord',
    name: 'flowDataRecord',
    component: () => import('../views/account-mobile/FlowDataRecord.vue')
}]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next();
})

export default router
