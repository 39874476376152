import Vue from 'vue';
import App from './App.vue';
import './utils/axios';
import router from './router';
import global from './utils/global.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {Message} from 'element-ui';
import * as echarts from 'echarts';
import qs from 'qs';

/* 阻止VUE在启动时生成生产提示 */
Vue.config.productionTip = false;

/* 全局变量 */
Vue.prototype.$global = global;
Vue.prototype.$paramData = {data: []};
Vue.prototype.$categoryData = {data: []};

/* ElementUI */
Vue.use(ElementUI);

/* ElementUI-消息 */
Vue.prototype.$message.info = function (msg) {
    return Message.info({message: msg, duration: 1000, offset: 6});
}
Vue.prototype.$message.success = function (msg) {
    return Message.success({message: msg, duration: 2000, offset: 6});
}
Vue.prototype.$message.warning = function (msg) {
    return Message.warning({message: msg, duration: 2000, offset: 6});
}
Vue.prototype.$message.error = function (msg) {
    return Message.error({message: msg, duration: 3000, offset: 6});
}

/* ECharts图表 */
Vue.prototype.$echarts = echarts;

/* 查询字符串解析和序列化字符串 */
Vue.prototype.$qs = qs;

new Vue({
    router,
    render: h => h(App),
    created() {
        console.log('欢迎使用：' + this.$global.systemName);
    },
}).$mount('#app')
