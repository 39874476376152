// 系统名称
const systemName = 'Buding后台管理系统';

// 按钮点击后恢复原来的背景色
function btnResume(event) {
    let target = event.target;
    if (target.nodeName === 'I' || target.nodeName === 'SPAN') {
        target = event.target.parentNode;
    }
    target.blur();
}

export default {
    // data
    systemName,

    // methods
    btnResume,
}