"use strict";

import Vue from 'vue';
import axios from "axios";
import router from "@/router";
import {Loading, Message, MessageBox} from "element-ui";
import {getToken, removeToken} from "@/utils/auth";

let loading;
let loadingNum = 0;  //内存中正在请求的数量

// 打开加载动画
function startLoading() {
    if (loadingNum === 0) {
        loading = Loading.service({
            lock: true,
            text: '拼命加载中...',
            background: 'rgba(255,255,255,0.1)',
            target: document.querySelector('.content')
        })
    }
    loadingNum++;
}

// 关闭加载动画
function endLoading() {
    loadingNum--;
    if (loadingNum <= 0) {
        loading.close();
    }
}

let config = {};
const _axios = axios.create(config);

_axios.interceptors.request.use(function (config) {
        if (config.url !== 'users/token') {
            startLoading();  //打开加载动画
        }
        // 让每个请求（登录除外）携带自定义的token
        if (config.url !== '/users/login' && getToken()) {
            config.headers['Authorization'] = getToken();
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    function (response) {
        if (response.config.url !== 'users/token') {
            endLoading();  //关闭加载动画
        }
        const code = response.data.code;
        const msg = response.data.msg;
        if (!sessionStorage.getItem('lastResponseCode')) {
            sessionStorage.setItem('lastResponseCode', '');
        }
        switch (code) {
            // 请求成功
            case 200 : {
                sessionStorage.setItem('lastResponseCode', code);
                return response;
            }
            // 登录过期，认证失败
            case 401 : {
                // 防止多次请求多次弹窗
                if (sessionStorage.getItem('lastResponseCode') !== '401') {
                    MessageBox.confirm('登录已过期, 是否重新登录?', '提示', {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '停在此页',
                        type: 'warning'
                    }).then(() => {
                        // 清空token，重新登录
                        removeToken();
                        router.replace({
                            path: '/login',
                            query: {redirect: router.currentRoute.fullPath}
                        }).then();
                    });
                }
                sessionStorage.setItem('lastResponseCode', code);
                return Promise.reject(new Error(msg));
            }
            // 请求失败
            case 500 : {
                Message({
                    message: msg,
                    type: 'error',
                    duration: 1000
                })
                sessionStorage.setItem('lastResponseCode', code);
                return Promise.reject(new Error(msg));
            }
            // 其他未知情况
            default : {
                // 调用了Excel导出接口，无状态码，返回的是字节码
                // 或者axios获取项目内json文件，无状态码，返回的是json字符串
                if (response.request.responseURL.search("export") > 0
                    || response.request.responseURL.search("online") > 0) {
                    return response;
                } else {
                    let errMsg = '未知异常';
                    Message({
                        message: errMsg,
                        type: 'error',
                        duration: 1000
                    })
                    sessionStorage.setItem('lastResponseCode', code);
                    return Promise.reject(new Error(errMsg));
                }
            }
        }
    },
    function (error) {
        endLoading();  //关闭加载动画
        Message({
            message: '接口异常',
            type: 'error',
            duration: 1000
        })
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
